import { provider } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getAuth, signInWithRedirect } from 'firebase/auth';
import { getRedirectResult, GoogleAuthProvider } from 'firebase/auth';
import { Navigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc';
// import { FiMail } from 'react-icons/fi';

const auth = getAuth();
getRedirectResult(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const provider = new GoogleAuthProvider();
  const handleLogin = async (e) => {
    try {
      signInWithRedirect(auth, provider);
      navigate('/');
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
    } catch (error) {
      alert('メールアドレスまたはパスワードが間違っています');
    }
  };

  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  });

  return (
    <>
      <h1><a href="../" class="title">のりモコン</a></h1>
      {user ? (
        <Navigate to={`/`} />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div>
              <label>メールアドレス</label>
              <br />
              <input
                name="email"
                type="email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </div>
            <div>
              <label>パスワード</label>
              <br />
              <input
                name="password"
                type="password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            </div>
            <button>メールログイン </button>
          </form>
        </>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleLogin} class="google">
        <h2>
          <FcGoogle />{' '}
        </h2>
      </button>
      <p>
        新規登録は<Link to={`/register/`}>こちら</Link>
      </p>
    </>
  );
};

export default Login;
