import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Page404 from './Page404';
import { AuthProvider } from './context/AuthContext';
// import PrivateRoute from "./components/PrivateRoute";
// import PublicRoute from "./components/PublicRoute";

const App = () => {
  return (
    <AuthProvider>
      <div className="container">
        <BrowserRouter>
          <Routes>
            <Route path={`/`} element={<Home />} />
            <Route path={`/register/`} element={<Register />} />
            <Route path={`/login/`} element={<Login />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
        
      </div>
    </AuthProvider>
  );
};

export default App;
