import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase.js';
import { useNavigate, Navigate } from 'react-router-dom';

const Home = () => {
  const [user, setUser] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
  }, []);

  const navigate = useNavigate();

  const logout = async () => {
    await signOut(auth);
    navigate('/login/');
  };

  return (
    <>
      {!loading && (
        <>
          {!user ? (
            <Navigate to={`/login/`} />
          ) : (
            <>
              <p class="top">
                現在ログイン中です（{user?.email}）
                <button onClick={logout} class="logout">
                  ログアウト
                </button>
              </p>
              <h1><a href="./" class="title">のりモコン</a></h1>
              <ul>
                <li>メニュー1</li>
                <li>メニュー2</li>
                <li>メニュー3</li>
                <li>メニュー4</li>
              </ul>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Home;
