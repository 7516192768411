import React from 'react';

const Page404 = () => {
  return (
    <div>
      <h2>404</h2>
      <p>Sorry, the page you're looking con not found.</p>
    </div>
  );
};
export default Page404;
